<template>
  <div class="success bg-slate min-h-screen">
    <the-navigation></the-navigation>

    <div
      class="container flex justify-center items-center m-auto flex-col py-10"
    >
      <div class="image max-w-2xl">
        <img src="@/assets/Images/home/success.png" alt="" class="w-full" />
      </div>

      <div class="my-14 max-w-6xl">
        <p>
          <span class="text-primary font-semibold">Congrats {{ name }}:</span>
          Your answers have been submitted successfully. The team will review
          and get back to you in a few days. Stay blessed.
        </p>
      </div>

      <div class="button">
        <router-link
          :to="{ name: 'Results' }"
          @click="closepopup"
          class="
            bg-tetiary
            px-8
            py-4
            bg-opacity-80
            rounded-md
            hover:bg-opacity-100
            text-white
            font-semibold
            block
            text-center
            cursor-pointer
            transition
            w-60
          "
          >See results</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import TheNavigation from "../components/TheNavigation.vue";
export default {
  components: { TheNavigation },
  name: "SuccessPage",
  data() {
    return {
      name: this.$store.state.user.quizTaker.name,
    };
  },
};
</script>

<style scoped>
</style>